import "./src/styles/global.scss";

export const onInitialClientRender = () => {
  const popup = document.querySelector(".homepopup");

  if (popup !== null) {
    popup.classList.add("homepopup--active");
  }
};

export const onRouteUpdate = () => {
  // Find the existing script element
  const existingScript = document.querySelector(
    'script[src="https://grwapi.net/widget.min.js"]'
  );

  // Remove the existing script if it exists
  if (existingScript) {
    existingScript.parentNode.removeChild(existingScript);
  }

  // Create a new script element
  const script = document.createElement("script");
  script.src = "https://grwapi.net/widget.min.js";
  script.async = true;

  // Append the new script to the document's head
  document.head.appendChild(script);
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0, 0);

  return false;
};
